import React, { useState } from "react";
import {
  handleApiErrors,
  showAlertDialouge,
  validatePhoneNumber
} from "../../helpers/utils";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { SignUpFormFields } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import useSendVerificationCode from "../CustomHooks/useSendVerificationCode";
import { useDispatch } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import BrandLogo from "../BrandLogo/BrandLogo";
import useCheckIfPhoneNumberExists from "../CustomHooks/useCheckIfPhoneNumberExists";
import { getBaseUrl } from "../../ApplicationSettings";

const PhoneNumberSignUpPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });
  const { state } = useLocation();

  const navigate = useNavigate();
  const { sendVerificationCode } = useSendVerificationCode();
  const [
    isVerificationCodeRequestInProgress,
    setIsVerificationCodeRequestInProgress
  ] = useState(false);
  const dispatch = useDispatch();
  const { checkIfPhoneNumberAlreadyExists } = useCheckIfPhoneNumberExists();

  function getSignInPageUrlQueryParams() {
    let params = {};
    if (state?.hasSmartScanProgress) {
      params.hasSmartScanProgress = true;
    }
    return params;
  }

  async function verfiyPhoneNumber(data) {
    if (data.phone) {
      let isUserWithSamePhoneNumberExists =
        await checkIfPhoneNumberAlreadyExists(data);

      if (!isUserWithSamePhoneNumberExists) {
        dispatch(showLoadingSpinner());
        setIsVerificationCodeRequestInProgress(true);
        sendVerificationCode(
          data.phone,
          function (response) {
            dispatch(hideLoadingSpinner());
            setIsVerificationCodeRequestInProgress(false);
            if (response.success) {
              navigate("/submit-verification-code", {
                state: {
                  ...state,
                  phoneNumber: data.phone
                }
              });
            } else if (response.message) {
              showAlertDialouge("Error", response.message);
            }
          },
          function (error) {
            dispatch(hideLoadingSpinner());
            setIsVerificationCodeRequestInProgress(false);
            handleApiErrors(error);
          }
        );
      } else {
        showAlertDialouge(
          "Phone number already exists",
          <>
            An account with the provided phone number already exists. Please{" "}
            <a
              href={
                getBaseUrl() +
                "/sign-in-with-phone?" +
                $.param(getSignInPageUrlQueryParams())
              }
            >
              sign in
            </a>{" "}
            or try with another phone number
          </>
        );
      }
    }
  }

  return (
    <div className="container">
      <BrandLogo />
      <div className="row">
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
          <form
            className="bg-white border-radius-xlg px-4 px-md-5 py-4"
            onSubmit={handleSubmit(verfiyPhoneNumber)}
          >
            <h2 className="text-center text-violet mb-4">
              Where should we send the results?
            </h2>
            <div className="form-group mb-3">
              <label className="form-label">
                Phone Number (XXX)-XXX-XXXX
                <span className="text-danger">*</span>
              </label>
              <InputMask
                mask="(999)-999-9999"
                className="form-control"
                placeholder="(XXX)-XXX-XXXX"
                {...register(SignUpFormFields.Phone, {
                  required: "This field is required.",
                  min: 14,
                  validate: (value) => validatePhoneNumber(value)
                })}
                inputmode="numeric"
              />
              {errors.phone?.message ? (
                <p className="help-block">{errors.phone.message}</p>
              ) : (
                <></>
              )}
              <label
                className="form-check-label fs-12 text-muted mt-1 d-inline-block w-100"
                htmlFor="saveBillingInfo1"
              >
                You will be notified via text when your results are ready,
                standard data rates may apply
              </label>
            </div>
            <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
              <button
                disabled={isVerificationCodeRequestInProgress}
                type="submit"
                className="btn btn-secondary btn-rounded btn-lg px-5"
              >
                Verify Phone Number
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberSignUpPage;
