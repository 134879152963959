import {
  getCICIApiUrl,
  getWhitenessImageAPIUrl,
  getCiCiAnonymousChatURL
} from "../ApplicationSettings";
import {
  postFormDataToWhitenessImageAPI,
  postJsonDataToCICI,
  postJsonDataForAnonymousChat,
  postJsonDataForOfficeCICIChat
} from "./httpReqExternal";

export const sendMessageToCICI_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getCICIApiUrl();
  postJsonDataToCICI(url, "POST", jsonData, success_callback, error_callback);
};

export const sendMessageToOfficeCICI_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getCICIApiUrl();
  postJsonDataForOfficeCICIChat(
    url,
    "POST",
    jsonData,
    success_callback,
    error_callback
  );
};

export const sendAnonymousMessageToCICI_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getCiCiAnonymousChatURL();
  postJsonDataForAnonymousChat(
    url,
    "POST",
    jsonData,
    success_callback,
    error_callback
  );
};

export const getWhitenessImage_Ajax = (
  formData,
  success_callback,
  error_callback
) => {
  var url = getWhitenessImageAPIUrl();
  postFormDataToWhitenessImageAPI(
    url,
    "POST",
    formData,
    success_callback,
    error_callback
  );
};
