import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { KeyCodes } from "../../constants";

const SubmitVerificationCodePage = (props) => {
  const {
    numDigits = 6,
    onSubmit = () => {},
    phoneNumber = "",
    onResend = () => {}
  } = props;
  const { handleSubmit, control, setValue, watch } = useForm();
  const inputRefs = useRef([]);

  useEffect(() => {
    if ("OTPCredential" in window) {
      const ac = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal
        })
        .then((otp) => {
          if (otp && otp.code) {
            otp.code.split("").forEach((digit, index) => {
              setValue(`digit${index}`, digit);
              if (inputRefs.current[index]) {
                inputRefs.current[index].focus();
              }
            });

            handleSubmit(onSubmitBtnClicked)();
          }
        })
        .catch((err) => {
          console.error("Error retrieving OTP:", err);
        });

      return () => ac.abort();
    }
  }, []);

  const handleInputChange = (e, index) => {
    const value = e.target.value.slice(-1);

    if (value) {
      setValue(`digit${index}`, value);

      if (index < numDigits - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.keyCode == KeyCodes.BACKSPACE) {
      e.preventDefault();

      setValue(`digit${index}`, "");

      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleFocus = (e) => {
    if (e.target.value) {
      e.target.select();
    }
  };

  const otpValues = watch(
    Array.from({ length: numDigits }, (_, i) => `digit${i}`)
  );
  const isAllFilled = otpValues.every((value) => value && value.length == 1);

  function onResendBtnClicked() {
    onResend();
  }

  function onSubmitBtnClicked(data) {
    let code = Object.values(data).join("");

    if (code) {
      onSubmit(code);
    }
  }

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("Text").slice(0, numDigits);

    if (pasteData.length === numDigits) {
      pasteData.split("").forEach((char, index) => {
        setValue(`digit${index}`, char);
        if (index < numDigits - 1) {
          inputRefs.current[index + 1]?.focus();
        }
      });
      e.preventDefault();
    }
  };

  return (
    <form
      className="bg-white border-radius-xlg px-4 px-md-5 py-4 otp-form"
      onSubmit={handleSubmit(onSubmitBtnClicked)}
    >
      <h2 className="text-left text-black fw-bold mb-2">
        Enter verification code
      </h2>
      <p className="text-muted">Enter the code we sent to {phoneNumber}</p>
      <div className="form-group d-flex text-center justify-content-center">
        {Array.from({ length: numDigits }).map((_, index) => (
          <Controller
            key={index + "digit"}
            name={`digit${index}`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                {...field}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                maxLength={1}
                className="otp-input text-center"
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onFocus={handleFocus}
                onPaste={handlePaste}
                autoComplete="one-time-code"
                inputmode="numeric"
              />
            )}
          />
        ))}
      </div>
      <label
        className="form-check-label text-center fs-12 text-muted mt-2 d-inline-block w-100"
        htmlFor="saveBillingInfo1"
      >
        Didn't receive a code?{" "}
        <span onClick={onResendBtnClicked} role="presentation">
          <a className="text-primary">Resend</a>
        </span>
      </label>
      <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
        <button
          type="submit"
          className="btn btn-secondary btn-rounded btn-lg px-5"
          disabled={!isAllFilled}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default SubmitVerificationCodePage;
