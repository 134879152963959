import React, { useState, useEffect, useRef } from "react";
import iconChat from "./../../assets/images/icon-chat.svg";
import iconCross from "./../../assets/images/icon-cross.png";
import ReceivedMessage from "./ReceivedMessage";
import SentMessage from "./SentMessage";
import MessageBox from "./MessageBox";
import {
  handleGoogleTranslatorOnPageLoad,
  getTranslatedLanguageCode
} from "./GoogleTranslateElement";
import { sendAnonymousMessageToCICI_Ajax } from "../../helpers/requestsExternal";
import userImgPlaceholder from "./../../assets/images/chat-cici.png";
import { saveAIChatMessages_Ajax } from "../../helpers/requests";
import moment from "moment";
import {
  handleApiErrors,
  isEmptyArray,
  showAlertDialouge
} from "../../helpers/utils";
import { startSpeaking, stopSpeaking } from "../../helpers/TextToSpeech";

const AnonymousCiCiChat = ({ sessionId, providerId }) => {
  const [isWaitingForReply, setIsWaitingForReply] = useState(false);
  const [isAITyping, setIsAITyping] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const exisitngMessagesRef = useRef([]);
  const chatListDivRef = useRef();
  const chatWrapperRef = useRef();
  const [messages, setMessages] = useState([
    {
      createdOn: moment.now(),
      message: "Hello! How can I assist you today?"
    }
  ]);
  const [messagesGroupedByDate, setMessagesGroupedByDate] = useState([]);
  const [playingMsgId, setPlayingMsgId] = useState(0);

  useEffect(() => {
    handleGoogleTranslatorOnPageLoad();
    document.body.classList.add("no-background");

    window.$("#chatModal").on("hidden.bs.modal", function () {
      setIsChatModalOpen(false);
    });

    window.$("#chatModal").on("show.bs.modal", function () {
      setIsChatModalOpen(true);
    });

    return () => {
      stopSpeaking();
      document.removeEventListener(handleTranslateClick);
      document.body.classList.remove("notranslate");
      document.body.classList.remove("no-background");
    };
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      processMessages(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (!chatListDivRef.current || !chatWrapperRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      setTimeout(scrollToBottom, 50);
    });

    resizeObserver.observe(chatListDivRef.current);
    return () => resizeObserver.disconnect();
  }, [chatListDivRef, chatWrapperRef, isAITyping]);

  const messageTypes = {
    sent: "sent",
    received: "received"
  };

  function renderMessages(messages) {
    return messages.map((message, index) => {
      if (message.type == messageTypes.received) {
        return (
          <>
            <ReceivedMessage
              key={message + index}
              id={"receivedMsg-" + index}
              message={message?.message}
              userImg={message?.userImg}
              shouldShowPlayBtn={true}
              isPlaying={isMsgPlaying(message.aiChatMessageId, playingMsgId)}
              playBtnClicked={() => {
                playBtnClicked(
                  message.message,
                  message.aiChatMessageId,
                  "receivedMsg-" + index
                );
              }}
              pauseBtnClicked={pauseBtnClicked}
            />
          </>
        );
      } else {
        return (
          <>
            <SentMessage key={message + index} message={message?.message} />
          </>
        );
      }
    });
  }

  function playBtnClicked(message, messageId, elementId) {
    if (message) {
      let targetLang = getTranslatedLanguageCode();

      startSpeaking(
        message,
        function () {
          setPlayingMsgId(messageId);
        },
        function () {
          setPlayingMsgId(0);
        },
        elementId,
        targetLang
      );
    }
  }

  function pauseBtnClicked() {
    stopSpeaking(function () {
      setPlayingMsgId(0);
    });
  }

  function isMsgPlaying(messageId, playingMsgId) {
    return messageId == playingMsgId;
  }

  function processMessages(messages) {
    let newProcessedMessages = [];
    messages.forEach((msg) => {
      let processedMessage = {
        ...msg
      };

      let sentTime = msg?.createdOn;
      if (sentTime) {
        processedMessage.date = moment(sentTime).format("dddd MMM DD, YYYY");
        processedMessage.time = moment(sentTime).format("LT");
      } else {
        processedMessage.date = moment().format("dddd MMM DD, YYYY");
        processedMessage.time = moment().format("LT");
      }
      if (msg?.senderUserId > 0 || msg?.providerId > 0) {
        processedMessage.type = messageTypes.sent;
      } else {
        processedMessage.type = messageTypes.received;
        processedMessage.userImg = userImgPlaceholder;
      }
      newProcessedMessages.push(processedMessage);
    });

    if (newProcessedMessages.length > 0) {
      exisitngMessagesRef.current = [...newProcessedMessages];
      let msgGroupedByDate = newProcessedMessages.groupBy("date");
      setMessagesGroupedByDate(msgGroupedByDate);
    }
  }

  function sendMessage(inputMsg) {
    if (inputMsg.length > 0) {
      setIsWaitingForReply(true);

      let currentMsg = [...exisitngMessagesRef.current];

      let msgObj = {
        message: inputMsg,
        senderUserId: 0,
        providerId: providerId
      };

      if (currentMsg.length > 0) {
        saveMessages([inputMsg], messageTypes.sent);
        currentMsg.push(msgObj);
        setMessages([...currentMsg]);
        setTimeout(scrollToBottom, 50);
      }

      let formData = {
        provider_id: providerId,
        msg: inputMsg
      };

      setIsProcessing(true);

      sendAnonymousMessageToCICI_Ajax(
        JSON.stringify(formData),
        function (response) {
          if (!isEmptyArray(response)) {
            let nonEmptyMsg = response.filter((m) => m.length > 0);
            saveMessages(nonEmptyMsg, messageTypes.received);
          } else if (typeof response == "string") {
            saveMessages([response], messageTypes.received);
          }
          setIsProcessing(false);
          setIsWaitingForReply(false);
        },
        function (err) {
          handleApiErrors(err);
          setIsProcessing(false);
          setIsWaitingForReply(false);
        }
      );
    }
  }

  function saveMessages(messageToSave, messageType) {
    if (messageToSave && messageType) {
      let messageData = messageToSave.map((message) => {
        let msgObj = {};

        msgObj.smartScanId = 0;
        msgObj.message = message;
        msgObj.sessionId = sessionId;

        if (messageType == messageTypes.sent) {
          msgObj.senderUserId = 0;
          msgObj.providerId = providerId;
        }

        return msgObj;
      });

      let currentMsg = [...exisitngMessagesRef.current];

      saveAIChatMessages_Ajax(
        -1,
        JSON.stringify(messageData),
        function (response) {
          if (response.success && !isEmptyArray(response?.data?.chatMessages)) {
            let newChatMessages = response.data.chatMessages;
            setMessages([...currentMsg, ...newChatMessages]);
          } else if (!response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          handleApiErrors(err);
        }
      );
    }
  }

  function scrollToBottom(height) {
    if (!height) {
      height = chatWrapperRef.current.scrollHeight;
    }

    chatWrapperRef.current.scrollTo(0, height);
  }

  return (
    <>
      <div id={"anonymous-cici-chat"}>
        <button
          type="button"
          className="chat-button bg-white p-2 mb-4 me-4 border-0 rounded-circle position-fixed bottom-0 end-0 shadow"
          data-bs-toggle="modal"
          data-bs-target="#chatModal"
        >
          {isChatModalOpen ? (
            <img src={iconCross} alt="" />
          ) : (
            <img src={iconChat} alt="" />
          )}
          <span className="badge bg-primary fs-6 fw-normal rounded-pill px-1 mt-3 position-absolute top-0 start-100 translate-middle"></span>
        </button>
        <div
          className={"modal fade chat-modal shadow"}
          id="chatModal"
          data-bs-backdrop="false"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className={"modal-dialog modal-fullscreen"}>
            <div className="modal-content border-0 rounded-0">
              <div className="modal-header bg-secondary justify-content-start align-items-stretch p-0 rounded-0 position-relative">
                <div className="flex-1 d-flex align-items-center px-0 py-2">
                  <figure className="my-0 me-2">
                    <div className="chat-profile-initial me-2 rounded-circle d-flex justify-content-center align-items-center">
                      {"CC"}
                    </div>
                  </figure>
                  <div className="right flex-1 d-flex flex-column align-items-start">
                    <h4 className="name text-truncate">{"CiCi"}</h4>
                    <div className="fst-italic status online">
                      <span className="position-relative d-inline-block align-top w-100">
                        online
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  className="chat-close-btn bg-transparent fs-3 d-flex flex-wrap align-content-center px-2 py-0 m-0 border-0 opacity-25"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x-circle-fill"></i>
                </button>
              </div>
              <div className="modal-footer d-flex flex-wrap align-items-centre px-3 pb-3 border-0 translate">
                <div ref={chatWrapperRef} id="chat-wrapper-cici">
                  <div id="main-container" ref={chatListDivRef}>
                    {!isEmptyArray(messagesGroupedByDate) &&
                      messagesGroupedByDate.map((message, index) => {
                        return (
                          <div
                            key={message?.field + index}
                            className="chat-date-wrapper"
                          >
                            <div className="chat-date text-center text-muted fst-italic mb-3 opacity-75">
                              <span>{message?.field}</span>
                            </div>
                            {renderMessages(message.groupList)}
                          </div>
                        );
                      })}
                    {isWaitingForReply ? (
                      <div className="chatbox loading-animation">
                        <img src={userImgPlaceholder} className="author-img" />
                        <div className="chat-msg msg-output">
                          <div className="messages__item messages__item--typing">
                            <span className="messages__dot" />
                            <span className="messages__dot" />
                            <span className="messages__dot" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <MessageBox
                  isWaitingForReply={isWaitingForReply}
                  isProcessing={isProcessing}
                  sendMessage={sendMessage}
                  isAITyping={isAITyping}
                />
              </div>
              <div
                className="cicichattranslate"
                id="google_translate_element"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnonymousCiCiChat;
